import React from 'react';
import { Link } from 'react-router-dom';
import ExPayBanner from "../../expay-banner.jpg";
import { useAuth } from "../../contexts/AuthContext";

const Home = () => {
  const { currentUser } = useAuth();

  const containerStyle = {
    display: 'flex',
    height: '100vh',
  };

  const imageContainerStyle = {
    flex: '1',
    height: '100%',
  };

  const imageStyle = {
    width: '40vw',
    height: '100%',
  };

  const companyDetailsStyle = {
    flex: '1',
    padding: '20px',
    boxSizing: 'border-box',
    // backgroundColor: '#007bff' /* Change the background color as desired */
  };

  const headingStyle = {
    fontSize: '25px',
    marginBottom: '10px',
    color: '#2c3e50', // Dark blue color
  };

  const paragraphStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
    marginBottom: '10px',
  };

  const detailsStyle = {
    marginTop: '15px',
    marginLeft: '20px',
    listStyle: 'none', // Remove bullets
  };

  const iconStyle = {
    marginRight: '5px',
    color: '#3498db', // Blue color
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '18px',
    borderRadius: '5px',
    textDecoration: 'none',
    display: 'inline-block',
    marginTop: '15px',
    cursor: 'pointer',
  };

  return (
    <div className="homepage-container" style={containerStyle}>
      <div className="image-container" style={imageContainerStyle}>
        <img src={ExPayBanner} alt="ExPay Logo" style={imageStyle} />
      </div>
      <div className="company-details" style={companyDetailsStyle}>
        <h1 style={headingStyle}><strong>Expay Financial</strong></h1>
        <p style={paragraphStyle}>
          Expay Financial offers a straightforward solution for effective money/payroll management through its Prepaid Credit Card Services. A prepaid credit card is distinct from traditional credit cards as it is not linked to a bank account or line of credit. Expay gives you the freedom to pay your employees/contractors with our reloadable card instead of processing individual cheques for everyone.
        </p>

        <details style={detailsStyle}>
          <summary style={headingStyle}>
            Benefits for Employer
          </summary>
          <ul style={paragraphStyle}>
            <li>
              <strong>Cost Effective:</strong> Employers just need to send a list to Expay financial, instead of writing several checks on a weekly basis.
            </li>
            <li>
              <strong>Less Time Consuming:</strong> Instead of spending all day organizing payroll cheques for the staff and distributing it, Expay Cards reload process is just an email away.
            </li>
            <li>
              <strong>More Secure:</strong> Check frauds are on the rise, with our secure visa cards we eliminate the risk related to those frauds.
            </li>
          </ul>
        </details>

        <details style={detailsStyle}>
          <summary style={headingStyle}>
            Benefits for Employees
          </summary>
          <ul style={paragraphStyle}>
            <li>
              <strong>Convenience:</strong> Reloadable and easy to use, prepaid cards serve as a practical alternative to cash or checks.
            </li>
            <li>
              <strong>Avoid High Interest Rates:</strong> Prepaid cards eliminate concerns about accruing high debt due to the absence of interest rates.
            </li>
            <li>
              <strong>Control Spending:</strong> Ideal for budgeting, users can only spend what is loaded onto the card.
            </li>
          </ul>
        </details>

        {/* Conditional rendering for the button text */}
        {currentUser ? (
          <Link to="/account-details" style={buttonStyle}>
            Go to Account Details
          </Link>
        ) : (
          <Link to="/login" style={buttonStyle}>
            Login or Register
          </Link>
        )}
      </div>
    </div>
  );
};

export default Home;
