// App.js

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import Profile from "./components/accounts/Profile";
import Home from "./components/accounts/Home";

import "./components/accounts/Profile.css";

import WithPrivateRoute from "./utils/WithPrivateRoute";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";

import { Navigate } from "react-router-dom";

// import ErrorMessage from "./components/layouts/ErrorMessage";
// console.log = () => { };

function App() {

  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/" element={<Home />} />

          <Route
            exact
            path="/profile"
            element={
              <WithPrivateRoute>
                <Profile />
              </WithPrivateRoute>
            }
          />

<Route
            exact
            path="*"
            element={<Navigate to='/profile' />}
          />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;