// eslint-disable-next-line no-unused-vars

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../config/firebase";
import {
  getDatabase,
  ref,
  push,
  onValue,
  get,
  child,
  set,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";

export default function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [customerNum, setCustomerNum] = useState("");
  const [cardNum, setCardNum] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser, register, setError } = useAuth();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    setEmail(customerNum + "@expay.ca");
    console.log("this is database");
    console.log(database);

    console.log(ref);
    // if (email !== (customerNum + '@expay.ca')) {
    //   return console.log("Customer Numbers do not match")
    // }
    if (password !== confirmPassword) {
      return console.log("Passwords do not match");
    }

    try {
      setLoading(true);

      register(customerNum + "@expay.ca", password);

      console.log("user info");
      const usersRef = ref(database, "/users");
      setEmail(customerNum + "@expay.ca");

      push(usersRef, {
        email,
        customerNum,
        cardNum,
      });

      async function checkExistingEmail(email) {
        try {
          // Create a reference to the "users" node
          const usersRef = ref(database, "users");

          // Create a query to find matching email
          const queryRef = query(
            usersRef,
            orderByChild("email"),
            equalTo(email)
          );

          // Get the matching data
          const snapshot = await get(queryRef);

          if (snapshot.exists()) {
            // Email exists, retrieve the corresponding customer number
            const userData = snapshot.val();
            const customerNum = userData[Object.keys(userData)[0]].customerNum;
            const cardNum = userData[Object.keys(userData)[0]].cardNum;

            console.log("Customer Number:", customerNum);
            console.log("Card Number:", cardNum);
          } else {
            console.log("Email does not exist in the database.");
          }
        } catch (error) {
          console.log("Error checking existing email:", error);
        }
      }

      checkExistingEmail(currentUser.email);

      navigate("/profile");
    } catch (e) {
      setError("Failed to register");
    }

    setLoading(false);
  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
            Register your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleFormSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                id="email-address"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Customer Number"
                onChange={(e) => setEmail(e.target.value + "@expay.ca")}
              />
            </div>

            <div>
              <input
                id="customerNum"
                name="customer ID"
                type="text"
                autoComplete="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Confirm Customer Number"
                onChange={(e) => setCustomerNum(e.target.value)}
              />
            </div>

            <div>
              <input
                id="cardNum"
                name="card ID"
                type="text"
                autoComplete="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Last Four Digits of Card"
                onChange={(e) => setCardNum(e.target.value)}
              />
            </div>
            <div>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-900"
            >
              Register
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/login"
                className="text-blue-600 hover:underline dark:text-blue-500"
              >
                Already have an account? Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
