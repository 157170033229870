import { LogoutIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ExPayLogo from "../../ExPay_Final_17032023.png";

import "./Header.css";

export default function Header() {
  const [modal, setModal] = useState(false);
  const { currentUser } = useAuth();

  return (
    <>
      <nav className="navbar">
        <div className="container">

          <Link to="/home" className="logo">
            <img src={ExPayLogo} alt="ExPay Logo" className="logo-icon" />
          </Link>
        
          <div className="menu">
            {currentUser && (
              <>
                <button
                  className="logout-button"
                  onClick={() => setModal(true)}
                >
                  <LogoutIcon className="logout-icon" aria-hidden="true" />
                </button>

                {/* Add the button for redirecting to Home.js */}
               
              </>
            )}
          </div>
        </div>
      </nav>
      {modal && <Logout modal={modal} setModal={setModal} />}
    </>
  );
}
